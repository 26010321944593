/* Document Styles */
/* font-family: Titillium Web, sans-serif;
font-family: 'Lora', serif;
color: #85a2d1; */

html {
  overflow: hidden;
}

/* ==============  Video Landing  ============== */
/* .videoLanding {
  display: flex;
  flex-direction: column;
} */

.reconVideoImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-image:url('https://s3.amazonaws.com/eaaf.nicaragua/assets/Reconstruction/masaya_highway.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 50vw;
  overflow: hidden;
}

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 2;
  width:7vw;
  height: 4rem;
  border-radius: 4px;
  cursor: pointer;
}

.playButton img {
  width:2rem;
  height: 2rem;
}

.videoBackdrop {
  width: 100vw;
  height: 100vh;
}

body, html, .player, .fallback {
  background-color: rgba(0, 0, 0, 0) !important;
  background: rgba(0, 0, 0, 0) !important;
  overflow: hidden;
}

body.vp-center, .vp-center body, .vp-center {
  background-color: rgba(0, 0, 0, 0) !important;
  background: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0) !important;
}

div.vp-center {
  background: rgba(0, 0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0) !important;
}

.vp-shade .vp-shade-invisible {
  background-color: rgba(0, 0, 0, 0) !important;
}

.player .vp-video-wrapper {
  background-color: rgba(0, 0, 0, 0) !important;
}

.player {
  background-color: rgba(0, 0, 0, 0) !important;
}

.reconVideo iframe, .reconVideo object, .reconVideo embed  {
  background-color: transparent;
}

.reconVideo {
  height: 100vh;
  width: 100vw;
}

.landingLanguageButtons {
  z-index: 100;
  margin: 3rem 0 0rem 5rem;
}

.landingLanguageButtons p {
  font-family: Titillium Web, sans-serif;
  display: inline !important;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 600;
}

p#active {
  color: #85a2d1;
}

p:hover {
  color: #85a2d1;
}

.splashText {
  font-family: Titillium Web, sans-serif;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 0;
  left: 50%;
  height: 100vh;
  color: black;
  background-color: #F2F4F3;
}

.splashText h1, .splashText h2 {
  margin: 0 5rem 0 5rem;
  /* text-align: center; */
}

.splashText h1 {
  font-weight: 600;
  font-size: 2rem;
  flex-shrink: 10;
  line-height: 2.1rem;
}

.splashText h2 {
  font-size: 1.5rem;
  flex-shrink: 10;
}

.splashText h3 {
  flex-shrink: 10;
  white-space: pre-line;
  text-align: justify;
  margin: 0 5rem 1rem 5rem;
  font-size: 1rem;
  line-height: 1.3rem;
}

.splashText a, .splashText h3 span {
  text-decoration: none;
  color: #85a2d1;
}

.splashText a:hover {
  color: orange;
}

.splashText h3 span:hover {
  color: orange;
  cursor: pointer;
}

span.italics {
  font-style: italic;
  color: black !important;
  cursor: text !important;
}

.platformButton, .platformButtonEs {
  margin: 0 5rem 0 5rem;
  border: 1px solid #85a2d1;
  width: 13rem;
  padding: .5rem;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
}

.platformButton:hover, .platformButtonEs:hover {
 background-color: #85a2d1;
 color: white !important;
}

.platformButton:before, .platformButtonEs:before {
  font-weight: 600;
  color: #85a2d1;
  font-size: 1.1rem;
}

.platformButton:before {
  content: "View the platform here";
}

.platformButtonEs:before {
  content: "Vea la plataforma aquí";
}

.platformButton:hover:before, .platformButtonEs:hover:before {
  color: white;
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 3;
  margin: 1rem 0 2rem 0;
}

.GIEILogo, .SituLogo, .EAAFLogo {
  cursor: pointer;
  width: 6rem;
  margin: .5rem 1rem 1rem 1rem;
}

.GIEILogo {
  order: 1;
}

.EAAFLogo {
  order: 2;
}

.SituLogo {
  order: 3;
  margin-right: 5rem;
}

.GIEILogo:hover, .SituLogo:hover, .EAAFLogo:hover {
  color: purple;
}

.modalBackdrop, .videoBackdrop {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  background-color: rgba(220,220,220,0.8);
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-x: auto;
  z-index: 100;
}

.ballisticsModal {
  height: 100vh;
}

.ndfHFb-c4YZDc.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-bnBfGc, .embedded-doc iframe {
  opacity: .8 !important;
}

.ballisticsModal iframe {
  position: absolute;
  left: 25%;
  height: 100vh;
  width: 50vw !important;
  border: 0;
  z-index: 1001;
}

.reconVideo iframe {
  position: absolute;
  left: 15%;
  top: 10%;
  height: 80vh;
  width: 70vw;
  border: 0;
  z-index: 1001;
}

.modalBackdrop img, .videoBackdrop img {
  position: absolute;
  top: 2vh;
  right: 2vh;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  z-index: 1002;
}


/* Smartphones (landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 812px)
and (orientation: landscape) {

/* ==============  Video Landing  ============== */

  /* Warning Modal */
  .cuidadoModal {
    position: absolute;
    top: calc((100vh - 78vh) / 2);
    left: calc((100vw - 96vw) / 2);
    width: 90vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background-color: #85a2d1;
    border-radius: 4px;
    padding: 4vh 3vw;
    z-index: 1000;
  }

  .cuidadoModal img {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  h3.cuidado {
    font-family: Titillium Web, sans-serif;
    padding: 1rem 2rem;
    color: black;
    text-align: left !important;
  }

  .landingLanguageButtons {
    margin: 1rem 0 .5rem 2rem;
  }

  .landingLanguageButtons p {
    font-size: .9rem;
  }

  .splashText {
    width: 50vw !important;
    justify-content: flex-start;
  }

  .splashText h1 {
    margin: 0 0 0 2rem;
    font-size: .9rem;
  }

  .splashText h2 {
    font-size: .75rem;
    margin: 0 2rem;
  }

  .splashText h3 {
    font-size: .7rem;
    line-height: .9rem;
    padding: 0;
    margin: .5rem 2rem;
    overflow-y: scroll;
    height: 35vh !important;
  }

  .logoWrapper {
    margin: 0 0 0 2rem;
  }

  .GIEILogo, .SituLogo, .EAAFLogo {
    width: 3rem;
    height: auto;
    z-index: 101;
  }

  .platformButton, .platformButtonEs {
    padding: .25rem;
    margin: 0 0 0 2rem;
  }

  .platformButton::before, .platformButtonEs::before {
    font-size: .9rem !important;
  }

}

/* Smartphones (portrait) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 768px)
and (orientation: portrait) {

  /* **********  Video Landing  ********** */

  /* Warning Modal */
  .modalBackdrop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(220,220,220,0.8);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
  }

  .videoLanding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: scroll !important;
  }

  .reconVideoImg {
    display: flex;
    height: 35vh !important;
    width: 100vw !important;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .playButton {
    justify-content: center;
    width: 25vw !important;
    margin: 0 auto;
    align-self: center;
  }

  .landingLanguageButtons {
    margin: .25rem 1.5rem;
  }

  .landingLanguageButtons p {
    font-size: .9rem;
  }

  .splashText {
    top: 35vh !important;
    justify-content: space-around;
    left: 0;
    width: 100vw;
    height: 65vh !important;
  }

  .splashText h1, .splashText h2, .splashText h3 {
    margin: 0 1.5rem;
  }

  .splashText h1 {
    font-size: 1.1rem;
  }

  .splashText h2 {
    font-size: 1rem;
    margin-bottom: .5rem;
  }

  .splashText h3 {
    font-size: .9rem;
    line-height: 1rem;
  }

  .mobileScrollDiv {
    display: flex;
    flex-direction: column;
    height: 35vh !important;
    overflow-y: scroll;
  }

  .platformButton, .platformButtonEs {
    margin: 0 1.5rem .5rem 1.5rem;
    width: 10rem;
    padding: .1rem;
  }

  .platformButton:before, .platformButtonEs:before {
    font-size: .8rem;
  }

  .ballisticsModal {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh !important;
    width: 85vw;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    z-index: 100000;
}

  .ballisticsModal iframe,
  .ballisticsModal object,
  .ballisticsModal embed {
    width: 85vw !important;
    left: 0 !important;
  }

  .logoWrapper {
    margin: 3rem 0 0 0;
  }

  .GIEILogo, .SituLogo, .EAAFLogo {
    margin: .5rem .5rem;
    width: 4rem;
  }

  .SituLogo {
    margin-right: 2rem;
  }

  .ballisticsModal {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 90vw;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }

  .modalBackdrop img {
    width: 1.75rem;
    height: 1.75rem;
  }

}

/* iPad portrait */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {

/*  ============  Splash Landing  ============  */
.cuidadoModal {
  top: 35vh;
  left: 10vw;
  width: 80vw;
  height: 30vh !important;
  margin: 0;
}

.cuidadoModal img {
  top: 1rem;
  right: 1rem;
}

.cuidadoModal h3 {
  margin: 2rem;
}

.landingLangaugeButtons {
  margin-top: 5rem !important;
}

.landingLanguageButtons p {
  font-size: 1.5rem;
}

.splashText {
  height: 70vh;
  justify-content: space-between;
}

.splashText h1 {
  font-size: 2rem;
}

.splashText h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.splashText h3 {
  font-size: 1.25rem !important;
  line-height: 1.5rem;
}

.playButton {
  width: 7rem !important;
}

.logoWrapper img {
  width: 6rem;
}

.SituLogo, .GIEILogo, .EAAFLogo {
  margin: 2rem;
}

.SituLogo {
  margin-right: 8rem;
}

}

/* iPad Pro Portrait */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1.5) {

    /* Warning Modal */
    .modalBackdrop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(220,220,220,0.8);
      position: fixed;
      height: 100vh !important;
      width: 100vw !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100000;
    }

    .cuidadoModal {
      position: absolute;
      display: flex;
      top: 25%;
      left: 5%;
      flex-direction: column;
      justify-content: center;
      background-color: #85a2d1;
      z-index: 100000;
      border-radius: 5px;
      height: 45vh;
      width: initial;
      padding-top: .75rem;
    }

    .cuidadoModal h3 {
      color: black;
      font-size: 2rem;
      margin: 2rem 5rem;
    }

    .cuidadoModal img {
      position: absolute;
      width: 2rem;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      padding: .5rem;
      text-align: right;
    }

    .videoLanding {
      justify-content: space-between;
      align-items: center;
    }

    .splashText {
      margin-top: 0;
      height: 100vh;
    }

    .splashText iframe {
      height: 40vh !important;
    }

    .platformButton, .platformButtonEs {
      margin: 0 auto 3rem auto;
    }

    .SituLogo {
      margin-right: 5rem !important;
    }

    .upArrow img, .downArrow img {
      width: 4rem;
      bottom: 1.5rem;
    }

    .upArrow img {
      right: 21vw !important;
    }

    .downArrow img {
      right: 14vw !important;
    }

    #scrollNav-right {
      bottom: 7rem !important;
    }

    a.aboutButton {
      bottom: 5rem !important;
    }

    a.tweetButton {
      bottom: 1vh !important;
    }

    p.eventLandingText {
      height: 80vh !important;
    }

    p.carouselCaption {
      font-size: 1rem;
      padding: 0 1rem;
    }

    .eventTextContainer {
      height: 45%;
      position: absolute;
      top: 50%;
      justify-content: space-between;
      align-items: flex-start;
    }

    .eventContainer {
      position: initial;
      height: initial;
    }

    .eventTitle {
      margin-left: 1rem;
      order: 2;
    }

    .textClass {
      order: 2;
      position: initial;
    }

    p.eventText {
      height: 22rem;
      margin: 0 2.5rem 0 1rem;
    }

    .captionDivvy {
      order: 1;
      top: 10rem;
      padding-right: 2rem;
      margin-left: 1rem;
    }

    p.chapterCaption {
      font-size: 1rem;
    }

}

/* iPad Pro Landscape */
@media only screen
  and (min-width: 1024px)
  and (max-height: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    /* Warning Modal */
    .modalBackdrop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(220,220,220,0.8);
      position: fixed;
      height: 100vh !important;
      width: 100vw !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
    }

    .cuidadoModal {
      position: absolute;
      display: flex;
      top: 25%;
      flex-direction: column;
      justify-content: center;
      background-color: #85a2d1;
      z-index: 10000;
      border-radius: 5px;
      height: 45vh;
      width: 100vw;
      padding-top: .75rem;
    }

    .cuidadoModal h3 {
      color: black;
      font-size: 2rem;
      margin: 2rem 5rem;
    }

    .cuidadoModal img {
      position: absolute;
      width: 2rem;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      padding: .5rem;
      text-align: right;
    }

  }
